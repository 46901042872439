<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="6"
        ><v-textarea
          label="Json data"
          solo
          rows="10"
          v-model="strJson"
        ></v-textarea>
        <v-btn color="primary" @click="importData">Import</v-btn>
        <span v-html="msg"></span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Pouch from '@/store/Pouch.js'
export default {
  data() {
    return {
      strJson: '',
      msg: ''
    }
  },
  methods: {
    importData() {
      const self = this
      const data = JSON.parse(this.strJson)
      data.forEach(row => {
        Pouch.post(row)
          .then(res => {
            self.msg = res
          })
          .catch(error => {
            self.msg = error
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
